import { flexStyle } from '@lib/frontend/core/styles/flex.style';
import { ShapeProps } from '@lib/frontend/core/styles/shape.model';
import { StyleGetter } from '@lib/frontend/core/styles/style.model';
import { isNil } from 'lodash';
import { StyleSheet } from 'react-native';

export const shapeStyle = StyleSheet.create({
  fullWidth: { width: '100%' },
  overflowHidden: { overflow: 'hidden' },
  muted: { opacity: 0.5 },
  hidden: { display: 'none' },
  opaque: { opacity: 0 },
  visible: { opacity: 1 },
  minimized: { transform: [{ scale: 0.7 }] },
  zoomOut: { opacity: 0, transform: [{ scale: 0.9 }] },
  zoomIn: { opacity: 1, transform: [{ scale: 1 }] },
  noScale: { transform: [{ scale: 1 }] },
  absolute: { position: 'absolute' },
  relative: { position: 'relative' },
  below: { zIndex: -1 },
  above: { zIndex: 1 },
  absoluteFill: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  absoluteRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  absoluteLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    margin: 'auto',
  },
  absoluteTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  absoluteBottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
});

export const getShapeStyle: StyleGetter<ShapeProps> = ({
  above,
  absolute,
  absoluteBottom,
  absoluteFill,
  absoluteLeft,
  absoluteRight,
  absoluteTop,
  below,
  bottom,
  fullWidth,
  height,
  hidden,
  left,
  muted,
  opacity,
  opaque,
  overflowHidden,
  relative,
  right,
  top,
  width,
  isMinimized,
}) => [
  ...(fullWidth ? [shapeStyle.fullWidth] : isNil(width) ? [] : [{ width }, flexStyle.basisAuto]),
  ...(isNil(height) ? [] : [{ height }, flexStyle.basisAuto]),
  ...(hidden ? [shapeStyle.hidden] : []),
  ...(opaque ? [shapeStyle.opaque] : []),
  ...(overflowHidden ? [shapeStyle.overflowHidden] : []),
  ...(muted ? [shapeStyle.muted] : []),
  ...(isNil(opacity) ? [] : [{ opacity }]),
  ...(absolute ? [shapeStyle.absolute] : []),
  ...(relative ? [shapeStyle.relative] : []),
  ...(absoluteTop ? [shapeStyle.absoluteTop] : []),
  ...(absoluteBottom ? [shapeStyle.absoluteBottom] : []),
  ...(absoluteLeft ? [shapeStyle.absoluteLeft] : []),
  ...(absoluteFill ? [shapeStyle.absoluteFill] : []),
  ...(absoluteRight ? [shapeStyle.absoluteRight] : []),
  ...(above ? [shapeStyle.above] : []),
  ...(below ? [shapeStyle.below] : []),
  ...(isNil(top) ? [] : [{ top }]),
  ...(isNil(bottom) ? [] : [{ bottom }]),
  ...(isNil(left) ? [] : [{ left }]),
  ...(isNil(right) ? [] : [{ right }]),
  ...(isMinimized ? [shapeStyle.minimized] : []),
];
