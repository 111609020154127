export const CommonTheme = {
  animation: {
    duration: 200,
    transition: 400,
  },
  shape: {
    borderRadius: 5,
    spacing: 16,
    spacingTight: 8,
    spacingWhitespace: '0.4em',
  },
  colors: {
    black: '#000',
    white: '#FFF',
    primary: {
      light: '#BACFFF',
      main: '#5469d4',
      dark: '#00003B',
    },
    secondary: {
      light: '#e0e0e0',
      main: '#9e9e9e',
      dark: '#FFF',
    },
    success: {
      light: '#53B86A',
      main: '#28A745',
      dark: '#186429',
    },
    error: {
      light: '#fbc6c2',
      main: '#F44336',
      dark: '#aa2e25',
    },
  },
};
